.comment-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.comment-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  /*font-family: "Playwrite HU";*/
  font-family: "Ubuntu";
}

.form-group input,
.form-group textarea {
  width: calc(100% - 20px); /* Adjust width to account for padding */
  padding: 10px;
  margin: 0; /* Remove default margin */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  font-family: "Poppins";
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.comment-container button[type='submit'] {
  padding: 5px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Ubuntu";
  font-size: 22px;
}

.comment-container button[type='submit']:hover {
  background-color: #218838;
}

