@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

.provinceDistrictTable {
    width: 25%;
    margin: 25px auto;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Ubuntu", sans-serif;
  }
  
  .provinceDistrictTable thead tr {
    background-color: #10b818;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .provinceDistrictTable th,
  .provinceDistrictTable td {
    padding: 10px 15px;
  }
  
  .provinceDistrictTable tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .provinceDistrictTable tbody tr:nth-of-type(even) {
    background-color: #ffffff; /* Light grey for even rows */
  }

  .provinceDistrictTable tbody tr:nth-of-type(odd) {
    background-color: #eff9a8; /* Light grey for odd rows */
  }
  
  .provinceDistrictTable tbody tr:last-of-type {
    border-bottom: 2px solid #4a90e2; /* Blue color for the last row border */
  }
  
  .provinceDistrictTable tbody tr:hover {
    background-color: #c4c4c4; /* Light grey for hover effect */
  }
  
  @media (max-width: 600px) {
    .provinceDistrictTable {
      width: 90%; /* Full width on mobile devices */
    }
  
    .provinceDistrictTable th,
    .provinceDistrictTable td {
      padding: 8px 10px;
    }
  }
  /* Media query for mobile screens */
  @media (max-width: 768px) {

    .provinceMainTable {
      margin-left: -25px !important;
      width: 96% !important; 
      font-size: 13px !important;
    }
  }

  