.comments-list {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: "Ubuntu";
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .comment-item {
    padding: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    font-family: "Ubuntu";
  }
  
  .comment-header {
    display: flex; 
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .comment-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .comment-name {
    font-weight: bold;
  }
  
  .comment-date {
    font-size: 0.80em;
    color: #555;
  }
  
  .comment-text {
    font-size: 0.85em;
    line-height: 1.4;
    text-align: left;
  }

  .replyCommentBtn {
    background-color: #4b97fc;
    padding: 5px 10px;
    margin-top: 7px;
    border-radius: 3px;
    font-size: 17px;
    color: white;
    border: none;
    cursor: pointer;
    font-family: "Ubuntu";
  }
  .replyCommentBtn:hover {
    background-color: #0354c0;
  }
  .replyCommentBtn:active {
    box-shadow: 0 2px #666;
    transform: translateY(2px);
  }

  .replyCommentContainer {
    display: flex;
    font-family: "Ubuntu" !important;
    flex-direction: column;
    font-size: 15px !important;
    justify-content: space-between;
    padding-left: 25px;
    text-align: left !important;
  }
  
  /* Media query for mobile screens */
@media (max-width: 768px) {

  .comments-list {
    margin-left: -25px !important;
    width: 96% !important; /* Ensure the container takes full width on mobile */
    padding: 10px !important;
    font-size: 14px;
    
  }
  .replyCommentContainer {
    font-family: "Ubuntu" !important;
    font-size: 12px !important;
  }
  .replyCommentBtn {
    padding: 2px 6px !important;
    margin-top: 5px !important;
    font-size: 14px !important;
  }
}