@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

.map-container {
    position: relative;

  }
  
  /* Styling for tooltip */
  .map-container .state-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: none; /* Initially hidden */
  }
  
  /* Show tooltip on hover */
  .map-container path:hover + .state-tooltip {
    display: block;
  }