/* styles/Footer.css */
.footer {
  padding: 1rem 2rem; /* Adjusted padding for more balanced spacing */
  display: inline-block;
  border: 3px solid #333333;
  font-size: 1.2rem;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
  background: #ffffff;
  position: relative;
  margin-top: 2rem;
  background-color: teal;
  font-family: "Josefin Sans";
  font-size: 16px;
  width: 70%; /* Increased width for a wider footer box */
  text-align: left;
}

.footer::before {
  content: '';
  border: 2px solid #353535;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
  border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}

.footer-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.footer p {
  margin: 0.5rem 0;
}

.footer a {
  color: #333333;
  text-decoration: none;
}

.home-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333333;
}
/* Media query for mobile screens */
@media (max-width: 768px) {

  /* Fixing footer overflow on the right side */
  .footer {
    margin-left: -25px !important;
    margin-bottom: 20px;
    padding: 0.5rem 1rem !important; /* Adjusted padding for more balanced spacing */
    font-size: 14px;
    width: 96% !important;  /* Ensure footer takes full width on mobile */
    box-sizing: border-box; /* Include padding and border in the width and height calculations */
    padding: 1rem; /* Adjust padding for better fit */
  }

  /* Repositioning the homepage icon to the top */
  .home-icon {
    position: relative;
    top: auto; /* Reset the top positioning */
    right: auto; /* Reset the right positioning */
    margin-bottom: 1rem; /* Add margin below the icon */
    align-self: flex-start; /* Align it to the start */
    order: -1; /* Bring the icon to the top */
  }

  /* Adjusting order of other footer content */
  .footer-header {
    display: flex;
    flex-direction: column;
    order: 0; /* Ensure it comes after the home icon */
  }

  /* Keeping the footer content in a column layout */
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
